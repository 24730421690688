import { init, addMessages } from "svelte-i18n";
import en from "../public/locales/en.json";
import nl from "../public/locales/nl.json";
const useI18n = (locale) => {
    addMessages("en", en);
    addMessages("nl", nl);
    init({
        fallbackLocale: "en",
        initialLocale: locale,
    });
};
export default useI18n;
