<svg
  xmlns="http://www.w3.org/2000/svg"
  width="341.458"
  height="426.571"
  viewBox="0 0 341.458 426.571"
  ><defs
    ><linearGradient
      id="linear-gradient"
      x1="-16.455"
      y1="8.744"
      x2="-14.478"
      y2="6.687"
      gradientUnits="objectBoundingBox"
      ><stop offset="0" stop-color="#829c8e" /><stop
        offset="1"
        stop-color="#42c789"
      /></linearGradient
    ></defs
  ><g id="Group_28" transform="translate(-1038.467 -518.095)"
    ><g id="Group_27" transform="translate(1038.967 518.595)"
      ><path
        id="Union_1"
        d="M178.826,411.816V291.452a13.756,13.756,0,1,1,27.512,0V411.816a13.756,13.756,0,0,1-27.512,0Zm-44.706-26.652V192.582a13.756,13.756,0,1,1,27.511,0V385.164a13.756,13.756,0,1,1-27.511,0Zm89.412-115.205v-32.67a13.756,13.756,0,0,1,27.511,0v32.67a13.756,13.756,0,0,1-27.511,0Zm-44.707-23.213A13.756,13.756,0,1,1,192.582,260.5,13.756,13.756,0,0,1,178.826,246.746ZM89.413,232.99V91.132a13.756,13.756,0,0,1,27.512,0V232.99a13.756,13.756,0,0,1-27.512,0Zm89.413-31.811V108.327a13.756,13.756,0,0,1,27.512,0v92.852a13.756,13.756,0,1,1-27.512,0Zm44.707-8.6V58.462a13.756,13.756,0,0,1,27.511,0v134.12a13.756,13.756,0,1,1-27.511,0Zm44.707-44.707V13.756a13.756,13.756,0,0,1,27.512,0v134.12a13.756,13.756,0,0,1-27.512,0Zm-134.119,0V13.756a13.756,13.756,0,1,1,27.511,0v134.12a13.756,13.756,0,0,1-27.511,0ZM44.707,125.522V13.756a13.756,13.756,0,0,1,27.512,0V125.522a13.756,13.756,0,0,1-27.512,0Zm268.24-18.914V13.756a13.756,13.756,0,0,1,27.512,0v92.852a13.756,13.756,0,0,1-27.512,0ZM0,89.413V13.756a13.756,13.756,0,0,1,27.512,0V89.413A13.756,13.756,0,0,1,0,89.413ZM178.826,63.621V13.756a13.756,13.756,0,0,1,27.512,0V63.621a13.756,13.756,0,0,1-27.512,0Zm-89.413-17.2V13.756a13.756,13.756,0,0,1,27.512,0v32.67a13.756,13.756,0,0,1-27.512,0Zm134.12-32.67a13.756,13.756,0,1,1,13.756,13.756A13.756,13.756,0,0,1,223.533,13.756Z"
        stroke="rgba(0,0,0,0)"
        stroke-miterlimit="10"
        stroke-width="1"
        fill="url(#linear-gradient)"
      /></g
    ></g
  ></svg
>

<style>
  svg {
    width: 20px;
    height: 25px;
    margin-left: 8px;
  }
</style>
