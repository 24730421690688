<script lang="ts">
  import { _ } from "svelte-i18n";
  import Portfolio from "./Portfolio.svelte";

  const global = globalThis;

  const url = `https://thomaur.dev/${global.__LOCALE__}`;
</script>

<div class="footer">
  <div class="footer__container">
    <div class="footer__content">
      <h1>{$_("created")}</h1>
      <a href={url} target="_blank" rel="noreferrer"><Portfolio /></a>
    </div>
  </div>
</div>

<style lang="scss">
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    @apply py-6;

    &__container {
      @apply container;
      @apply mx-auto;
      /* display: inline-flex; */
    }

    &__content {
      display: inline-flex;
    }
  }
</style>
