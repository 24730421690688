const AMSTERDAM = {
    lat: 52.370216,
    lon: 4.89707,
};
export const GetLocationBasedOnIp = async () => {
    try {
        const response = await fetch(`https://geolocation-db.com/json/`);
        if (response.status >= 200 && response.status < 400) {
            const data = await response.json();
            if (data.city) {
                if (data.city === "Not found") {
                    return AMSTERDAM;
                }
                return data.city;
            }
            else {
                return AMSTERDAM;
            }
        }
        else {
            return AMSTERDAM;
        }
    }
    catch (error) {
        console.error(error);
        return AMSTERDAM;
    }
};
