<script lang="ts">
  export let location: string;
</script>

<div class="location">{location}</div>

<style lang="scss">
  .location {
    @apply px-4;
    @apply py-2;
    @apply font-medium;
    @apply text-white;
    @apply bg-gray-800;
    @apply rounded-full;
    @apply mb-2;
    @apply border-none;
    @apply text-center;

    @apply mx-auto;
    @apply my-3;
    display: block;
    max-width: 15rem;
  }
</style>
