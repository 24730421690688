<script lang="ts">
  import { METRICTYPES } from "../constants/metricTypes";

  import GetCorrectMetricValue from "../utils/GetCorrectMetricValue";
  import GetWeatherColor from "../utils/GetWeatherColor";

  export let title: string;
  export let temp: number;
  export let metric: string;
  export let size: "big" | "small";

  $: tempValue = GetCorrectMetricValue(temp, metric);
  $: metricValue = `°${metric === METRICTYPES.CELSIUS ? "C" : "F"}`;
</script>

<div class={`placeholder ${size}`}>
  <p>{title}</p>
  <h1 class={GetWeatherColor(temp)}>
    {tempValue}{metricValue}
  </h1>
</div>

<style lang="scss">
  .placeholder {
    text-align: center;
  }

  .big {
    @apply font-bold;
    @apply mx-4;
    h1 {
      @apply font-black;
      @apply text-7xl;
    }

    p {
      @apply text-xl;
    }
  }

  .small {
    margin-top: 16px;
    @apply font-bold;
    h1 {
      @apply text-3xl;
    }

    p {
      @apply text-base;
    }
  }
</style>
