import { METRICTYPES } from "../constants/metricTypes";
import GetCorrectMetricValue from "./GetCorrectMetricValue";
const GetWeatherColor = (temperature) => {
    const temp = GetCorrectMetricValue(temperature, METRICTYPES.CELSIUS);
    if (temp < -21)
        return "text-indigo-800";
    else if (temp < -18)
        return "text-indigo-700";
    else if (temp < -15)
        return "text-indigo-600";
    else if (temp < -12)
        return "text-indigo-500";
    else if (temp < -9)
        return "text-blue-400";
    else if (temp < -6)
        return "text-blue-300";
    else if (temp < -3)
        return "text-sky-300";
    else if (temp < -0)
        return "text-cyan-300";
    else if (temp < 3)
        return "text-teal-300";
    else if (temp < 6)
        return "text-emerald-300";
    else if (temp < 9)
        return "text-green-300";
    else if (temp < 12)
        return "text-lime-300";
    else if (temp < 15)
        return "text-amber-200";
    else if (temp < 18)
        return "text-amber-300";
    else if (temp < 21)
        return "text-orange-400";
    else if (temp < 24)
        return "text-orange-500";
    else if (temp < 27)
        return "text-orange-600";
    else if (temp < 30)
        return "text-red-600";
    else if (temp < 33)
        return "text-red-700";
    else if (temp < 36)
        return "text-red-800";
    else
        return "text-green-900";
};
export default GetWeatherColor;
