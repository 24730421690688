import * as ackeeTracker from "ackee-tracker";
const useAckee = (ackeeKey, ackeeUrl) => {
    if (ackeeKey && ackeeUrl) {
        const ackee = ackeeTracker.create(ackeeUrl, {
            ignoreLocalhost: false,
            ignoreOwnVisits: true,
            detailed: true,
        });
        ackee.record(ackeeKey);
    }
};
export default useAckee;
