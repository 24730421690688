<script lang="ts">
  import TempPlaceholder from "./TempPlaceholder.svelte";
  import { _ } from "svelte-i18n";

  export let temp: number;
  export let minTemp: number;
  export let maxTemp: number;
  export let metric: string;
</script>

<div>
  <TempPlaceholder title="Min" temp={minTemp} {metric} size="small" />
  <TempPlaceholder title={""} {temp} {metric} size="big" />
  <TempPlaceholder title="Max" temp={maxTemp} {metric} size="small" />
</div>

<style>
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>
