const apiKey = "a8a2833afc6fa7982e7ff924e00ab466";
export const GetWeatherDataByLocation = async (location) => {
    try {
        const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${location}&appid=${apiKey}`);
        if (response.status >= 200 && response.status < 400) {
            return await response.json();
        }
    }
    catch (error) {
        console.error(error);
    }
};
export const GetWeatherDataByCoords = async (coord) => {
    try {
        const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${coord.lat}&lon=${coord.lon}&appid=${apiKey}`);
        if (response.status >= 200 && response.status < 400) {
            return await response.json();
        }
    }
    catch (error) {
        console.error(error);
    }
};
