<script lang="ts">
  import classNames from "classnames";
  import { createEventDispatcher } from "svelte";
  import { METRICTYPES } from "../constants/metricTypes";

  export let metric: string;

  const dispatch = createEventDispatcher();
  const handleMetricChange = (metric: string) => {
    dispatch("metric", metric);
  };

  $: isCelsius = METRICTYPES.CELSIUS === metric;
</script>

<div class="metricSwitch">
  <button
    on:click={() => handleMetricChange(METRICTYPES.CELSIUS)}
    class={classNames("metricSwitch__left", {
      active: isCelsius,
    })}>°C</button
  >
  <button
    on:click={() => handleMetricChange(METRICTYPES.FAHRENHEIT)}
    class={classNames("metricSwitch__right", {
      active: !isCelsius,
    })}>°F</button
  >
</div>

<style lang="scss">
  .metricSwitch {
    @apply mt-2;
    display: flex;
    justify-content: center;

    &__left,
    &__right {
      @apply bg-gray-700;
      @apply px-4;
      @apply py-2;
      @apply text-xs;
      @apply font-semibold;
      @apply text-white;
      border: none;
      margin: none;
    }

    &__left {
      @apply rounded-l-full;
    }

    &__right {
      @apply rounded-r-full;
    }

    .active {
      @apply bg-blue-600;
    }
  }
</style>
