<script lang="ts">
  import { onMount } from "svelte";
  import Temperature from "./components/Temperature.svelte";
  import type { WeatherData } from "./types";
  import { GetLocationBasedOnIp } from "./services/GetLocationBasedOnIp";
  import {
    GetWeatherDataByCoords,
    GetWeatherDataByLocation,
  } from "./services/GetWeatherData";
  import MetricSwitch from "./components/MetricSwitch.svelte";
  import useI18n from "./i18n";
  import { _ } from "svelte-i18n";
  import Location from "./components/Location.svelte";
  import Footer from "./components/Footer.svelte";
  import useAckee from "./ackee";

  const global = globalThis;

  useI18n(global.__LOCALE__);
  useAckee(global.__ACKEE_KEY__, global.__ACKEE_URL__);

  let weatherData: WeatherData;
  let metric: string = $_("metric");

  onMount(async () => {
    const location = await GetLocationBasedOnIp();
    let data: WeatherData;
    if (typeof location === "string") {
      data = await GetWeatherDataByLocation(location);
    } else {
      data = await GetWeatherDataByCoords(location);
    }

    weatherData = { ...data };
  });

  const handleMetricChange = (event) => {
    metric = event.detail;
  };
</script>

<svelte:head>
  <title>{$_("title")}</title>
  <meta name="description" content={$_("description")} />
  <meta name="author" content="Thomas" />
  <meta name="keywords" content={$_("keywords")} />
</svelte:head>

<main>
  <div class="home">
    <h1 class="h1">{$_("title")}</h1>
    {#if weatherData}
      <Temperature
        {metric}
        temp={weatherData.main.temp}
        minTemp={weatherData.main.temp_min}
        maxTemp={weatherData.main.temp_max}
      />
      <Location location={weatherData.name} />
      <MetricSwitch on:metric={handleMetricChange} {metric} />
    {/if}
  </div>
  <Footer />
</main>

<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  html,
  body {
    @apply bg-gray-900;
    @apply text-white;
    padding: 0;
  }

  .h1 {
    text-align: center;
    @apply text-2xl;
    font-weight: bold;
  }

  .home {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
  }
</style>
